.App {
  text-align: center;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.admin-button {
  width: 200px;
  height: 50px;
  position: absolute;
  left: 70px;
  top: 47px;
  cursor: pointer;
}

.menu {
  position: absolute;
  top: 40px;
  right: 40px;
}

.menu div {
  float: left;
  margin-right: 20px;
  cursor: pointer;
  color: #316a77;
}

.show-qr-btn1 {
  width: 526px;
  height: 230px;
  position: absolute;
  left: 138px;
  top: 191px;
  cursor: pointer;
}

.show-qr-btn2 {
  width: 526px;
  height: 230px;
  position: absolute;
  left: 138px;
  top: 461px;
  cursor: pointer;
}

.qrcode {
  width: 500px;
  position: absolute;
  right: 200px;
  top: 170px;
}

.icp {
  position: absolute;
  bottom: 20px;
  text-align: center;
  width: 100%;

  text-decoration: none;
  color: #316a77;
  display: block;
}